import React from 'react';
import { Link } from 'gatsby';

export default () => {

  return (
    <Link to="/search">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.325 12.8992L19.705 18.2792C19.8941 18.4685 20.0003 18.7251 20.0002 18.9926C20.0001 19.2601 19.8937 19.5166 19.7045 19.7057C19.5153 19.8948 19.2587 20.001 18.9912 20.0009C18.7236 20.0008 18.4671 19.8945 18.278 19.7052L12.898 14.3252C11.2897 15.5709 9.26729 16.1571 7.24214 15.9646C5.21699 15.7721 3.34124 14.8153 1.99649 13.2888C0.651744 11.7624 -0.0609975 9.78105 0.0032639 7.74778C0.0675253 5.71451 0.903963 3.78209 2.34242 2.34364C3.78087 0.905183 5.71329 0.068746 7.74656 0.00448461C9.77982 -0.0597768 11.7612 0.652965 13.2876 1.99771C14.814 3.34246 15.7708 5.21821 15.9634 7.24336C16.1559 9.26851 15.5697 11.2909 14.324 12.8992H14.325ZM8.00001 14.0002C9.59131 14.0002 11.1174 13.3681 12.2427 12.2429C13.3679 11.1177 14 9.59153 14 8.00023C14 6.40893 13.3679 4.88281 12.2427 3.75759C11.1174 2.63237 9.59131 2.00023 8.00001 2.00023C6.40871 2.00023 4.88259 2.63237 3.75737 3.75759C2.63215 4.88281 2.00001 6.40893 2.00001 8.00023C2.00001 9.59153 2.63215 11.1177 3.75737 12.2429C4.88259 13.3681 6.40871 14.0002 8.00001 14.0002Z" fill="#3E3E3F"/>
      </svg>
    </Link>
  );

}