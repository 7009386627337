import React from 'react';
import SocialIcons from '../components/main-site-nav/mobile/socialIcons';
import EssentialEspressoELogo from '../components/assets/images/essentialEspressoELogo';

import '../components/assets/css/main-footer.scss';

export default () => (
  <footer className="main-footer">
    <nav>
      <ul>
        <li><EssentialEspressoELogo /></li>
        <li>Privacy &amp; Terms</li>
        <li>Disclaimer</li>
        <li>DMCA Policy</li>
        <li>Our Story</li>
        <li>Contact Us</li>
      </ul>
    </nav>
    
   <SocialIcons cssClassName={"inline-flex"} fillColor={"#283593"} />

   <p>Copywrite 2020 Essential Espresso</p>

  </footer>
)