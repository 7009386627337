import React, { useEffect, useState } from 'react';
import EssentialEspressoLogo from '../../assets/images/essentialEspressoLogo';
import { Link } from 'gatsby';

import './assets/css/desktop-nav.scss';

import MagnifyingGlassIcon from '../../assets/images/magnifyingGlassIcon';
import SocialShareIcon from '../../../components/assets/images/socialShareIcon';

export default ({ 
  navLinks: {
    allDatoCmsGlobalNavigation: {
      edges
     }
  }
}) => {

  const [links, setLinks] = useState(null);

  useEffect(
    () => {
      setLinks( edges[0].node.navigationItems );
    }, []
  );


  const createNavLinks = data => data.map( link => <li key={link.id}><Link to={`${link.target.handle}/`}>{link.target.title}</Link></li> );

  return (
    <header className="desktop-menu">
      <div className="header-grid-2">
        <nav>
          <ul className="inline-flex">
            <li><Link to="/"><EssentialEspressoLogo /></Link></li>
            { links ? createNavLinks(links) : null }
            <li><Link to="#">Our Story</Link></li>
            <li><Link to="#">Contact Us</Link></li>
          </ul>
        </nav>
        <div>
          <MagnifyingGlassIcon />
          <SocialShareIcon />
        </div>
      </div>
    </header>
  );
};