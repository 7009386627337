import React from 'react';

export default ({
  fillColor
}) => {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3829 4.0299C17.5624 4.02371 18.0694 4.01132 18.2619 4.02371C18.9553 4.02371 20.02 3.9618 20.7381 4.02371V7.11894H18.2619C17.3581 7.11894 17.0239 7.33561 17.0239 8.35704V10.8332H20.1191V13.9285H17.0239V22.5951H13.9286V13.9285H11.4524V10.8332H13.9286L13.8909 8.09704C13.8909 6.20213 14.2753 5.05132 15.8409 4.37037C16.2439 4.19704 16.8567 4.04228 17.3829 4.0299ZM0.30957 0.928467V25.0713H24.4524V0.928467H0.30957Z" fill={fillColor} />
    </svg>
  );
}