import React from 'react';
import FacebookSocialIcon from '../../../components/assets/images/facebookSocialIcon';
import TwitterSocialIcon from '../../../components/assets/images/twitterSocialIcon';
import InstagramSocialIcon from '../../../components/assets/images/instagramSocialIcon';
import PinterestSocialIcon from '../../../components/assets/images/pinterestSocialIcon';

import '../mobile/assets/css/to-share.css';

export default ({ cssClassName, fillColor }) => {

  const getCssClassName = cssClassName => cssClassName ? cssClassName : '';

  return (
    <ul className={`no-list-style-type ${getCssClassName(cssClassName)}`}>
      <li><FacebookSocialIcon fillColor={fillColor} /></li>
      <li><TwitterSocialIcon  fillColor={fillColor} /></li>
      <li><InstagramSocialIcon  fillColor={fillColor} /></li>
      <li><PinterestSocialIcon  fillColor={fillColor} /></li>
    </ul>
  );
}