import React, { useEffect, useRef, useState } from 'react';
import { useMenuStackAnimation } from './assets/utils/useMenuStackAnimation';
import './assets/css/menu-stack.css';

export default ({ handleBlueCurtainVisibility }) => {

  const topLineRef = useRef();
  const middleLineRef = useRef();
  const bottomLineRef = useRef();

  const [ menuOpen, setMenuOpen ] = useState(false);

  const tl = useMenuStackAnimation({ topLineRef, middleLineRef, bottomLineRef });

  useEffect(() => {

    menuOpen ? tl.play() : tl.reverse();
    handleBlueCurtainVisibility(menuOpen);
    }, [menuOpen]);

  const isMenuOpen = () => menuOpen ? "stack-line white" : "stack-line black";

  return (
    <div id="stack-menu-icon">
      <svg onClick={ () => setMenuOpen(!menuOpen) } data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <line ref={ topLineRef } className={isMenuOpen()} y1="5" x2="28" y2="5" />
      <line ref={ middleLineRef } className={isMenuOpen()} y1="12" x2="28" y2="12" />
      <line ref={ bottomLineRef } className={isMenuOpen()} y1="19" x2="28" y2="19" />
    </svg>
    { menuOpen ? <p>Close Menu</p> : null}
    </div>
  );
}