import React from 'react';

export default ({
  fillColor
}) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 0.619141C20.3555 0.757188 25.3327 6.39733 25.3809 13.0001C25.2491 20.0349 19.6028 25.3328 13 25.381C11.8147 25.3731 10.6362 25.2023 9.49743 24.8734C9.74598 24.4683 9.9779 24.0532 10.1926 23.6291C10.6817 22.6362 10.9045 21.572 11.1552 20.5543C11.2623 20.1179 11.414 19.5422 11.6096 18.8297C11.8238 19.2395 12.2113 19.596 12.7734 19.8994C14.2381 20.6125 16.0544 20.376 17.3587 19.7118C19.1886 18.673 20.2608 17.0586 20.8347 15.2466C21.9409 11.0433 20.5146 7.00462 16.7031 5.379C12.1073 3.99543 6.97852 5.72319 5.19133 9.83119C4.41133 12.5773 4.45714 16.1021 7.16981 17.2517C7.31219 17.3056 7.44219 17.3056 7.55733 17.2517C7.81238 17.1062 8.10828 15.8452 8.05257 15.5803C8.03524 15.4998 7.97209 15.3884 7.865 15.246C6.36566 13.2168 7.27938 10.1958 8.68028 8.68162C11.0636 6.63505 14.7717 6.30324 16.957 8.25385C18.8952 10.5152 18.455 14.0345 17.2108 16.2761C16.5236 17.3718 15.6012 18.2007 14.3631 18.2156C13.0594 18.1865 12.0764 17.0827 12.4107 15.8347C12.6954 14.3707 13.4835 12.8169 13.507 11.4494C13.4439 10.3122 12.8756 9.591 11.8232 9.578C10.1431 9.78971 9.46462 11.3602 9.44233 12.7871C9.49619 13.4724 9.53704 14.1707 9.84285 14.739C9.55389 15.8994 9.27758 17.0628 9.01395 18.2292C8.71371 19.7948 8.12376 21.4321 8.03771 22.9488C8.0116 23.4118 8.00747 23.8758 8.02533 24.3392C3.43757 22.1632 0.644426 17.9135 0.617188 13.0013C0.752759 5.85443 6.39724 0.668045 13 0.619141Z" fill={fillColor} />
    </svg>
  );
}