import React from 'react';

export default ({
  fillColor
}) => {
  return (
    <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.3809 2.86643C25.215 3.21248 24.9067 3.65015 24.4554 4.17757C24.0048 4.70686 23.4489 5.17115 22.7859 5.57229C22.8044 5.71777 22.8181 5.85457 22.8273 5.98148C22.8985 7.94572 22.4231 9.983 21.7644 11.6798C20.4861 14.8357 18.5287 17.3757 15.7324 19.1003C12.8167 20.7569 9.42064 21.1085 6.29197 20.8083C4.21445 20.5724 2.16664 19.9008 0.619019 18.6354C3.45054 18.9697 6.09387 18.0164 8.18316 16.4762C5.86173 16.5344 4.17607 14.8716 3.37935 12.9501C3.7384 13.0436 4.11416 13.0324 4.45649 13.0058C4.87745 12.9656 5.27735 12.9272 5.66983 12.8411C4.1903 12.3682 2.89588 11.4997 2.21988 10.1904C1.82369 9.36086 1.64416 8.57096 1.64045 7.67581C2.33378 8.03734 3.16764 8.39019 3.93154 8.35862C2.77826 7.39167 1.8534 6.15605 1.6813 4.73781C1.57235 3.55543 1.87197 2.47643 2.30283 1.47234C4.02749 3.34867 5.96326 4.89443 8.19616 5.84467C9.72335 6.46372 11.2456 6.79553 12.8204 6.8011C12.6384 5.40886 12.7796 4.06305 13.4413 2.92091C14.2201 1.6921 15.3561 0.980194 16.6294 0.624861C18.4525 0.161813 20.2304 0.816766 21.3503 2.07343C22.5574 1.94467 23.7144 1.41353 24.635 0.871242C24.2437 2.04434 23.4823 3.21 22.3711 3.76838C23.4297 3.57462 24.4387 3.26448 25.3809 2.86643Z" fill={fillColor} />
    </svg>
  );
}