import React, { useEffect, useRef } from 'react';
import { useCurtainAnimation } from './assets/utils/useCurtainAnimation';
import { Link } from 'gatsby';
import './assets/css/curtain-menu.css';

import SocialIcons from '../mobile/socialIcons'; 


export default ({ shouldDropCurtain, largerLinks }) => {

  const curtainRef = useRef();

  const tl = useCurtainAnimation({ curtainRef });
  const createLargerLinks = data => data.map( link => <li key={link.id}><Link to={`${link.target.handle}/`}>{link.target.title}</Link></li> );

  useEffect(
    () => {
      shouldDropCurtain ? tl.play() : tl.reverse();
    }, [shouldDropCurtain]
  );

  return (
    <div ref={curtainRef} className="curtain-menu">
      <ul>
        <li><Link to="/search">Search</Link></li>
        {largerLinks ? createLargerLinks(largerLinks) : null }
      </ul>
      <ul style={{ borderTop: "1px solid #283593" }}>
        <li><Link to="#">Our Story</Link></li>
        <li><Link to="#">Contact Us</Link></li>
      </ul>

      <SocialIcons cssClassName={"inline-flex"} fillColor={"#A4BAF5"} />

      <p>Copywright 2020 Essential Espresso</p>
    </div>
  );
}