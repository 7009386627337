import React, { useEffect, useState } from 'react';
import gsap from 'gsap';

export const useCurtainAnimation = ({curtainRef}) => {

  const [ tl, setTl ] = useState( gsap.timeline({ paused: true }) )

  useEffect(() => {
    tl
      .to(
        curtainRef.current,
        0.5,
        { 
          top: "0",
          height: "100%",
          autoAlpha: 1
         }
      );
  }, []);

  return tl;
};