import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import EssentialEspressoLogo from '../../assets/images/essentialEspressoLogo';
import MenuStack from '../mobile/menuStack';
import MagnifyingGlassIcon from '../../assets/images/magnifyingGlassIcon';
import SocialShareIcon from '../../../components/assets/images/socialShareIcon';
import CurtainMenu from '../mobile/curtainMenu';

import './assets/css/mobile-main-header.scss';


export default ({ 
    navLinks: {
      allDatoCmsGlobalNavigation: {
        edges
       }
    }
  }) => {

  const [links, setLinks] = useState(null);
  const [dropCurtain, setDropCurtain] = useState(false);

  useEffect(
    () => {
      setLinks( edges[0].node.navigationItems );
    }, []
  );
  
  const handleStackIsOpen = bool => setDropCurtain(bool);
  
  return (
    <header className="mobile-header">
      <div className="first-row">
        <MenuStack handleBlueCurtainVisibility={handleStackIsOpen} />
        <Link to="/"><EssentialEspressoLogo /></Link>
        <div>
          <MagnifyingGlassIcon />
          <SocialShareIcon />
        </div>
      </div>
      <CurtainMenu shouldDropCurtain={dropCurtain} largerLinks={links} />
    </header>
  );
};



/*

const createNavLinks = data => data.map( link => <li key={link.id}><Link to={`${link.target.handle}/`}>{link.target.title}</Link></li> );

<nav className="second-row horizontal-scroll-wrapper">
        <ul className="horizontal-scroll">
          { links ? createNavLinks(links) : null }
        </ul>
      </nav>

*/