import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import DesktopMenu from '../../components/main-site-nav/desktop';
import MobileMenu from '../../components/main-site-nav/mobile';

import useWindowWidth from '../../utils/useWindowWidth';

export default () => {
  
  const data = useStaticQuery(
    graphql`
      query {
        allDatoCmsGlobalNavigation {
          edges {
            node {
              navigationItems {
                target {
                  ... on DatoCmsArticle {
                    id
                    title
                  }
                  ... on DatoCmsCategory {
                    id
                    title
                    handle
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const windowSize = useWindowWidth();

  const [headerCmpt, setHeaderCmpt] = useState(null);

  useEffect(
    () => {
      windowSize.width <= 1189 ? setHeaderCmpt(<MobileMenu navLinks={data} />) : setHeaderCmpt(<DesktopMenu navLinks={data} />)
    }, [windowSize.width]
  );
   
  return headerCmpt 
  
}
