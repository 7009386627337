import React from 'react';

export default ({
  fillColor
}) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.28052 13.3094C5.28052 17.4007 8.59862 20.72 12.6905 20.72C16.7818 20.72 20.1011 17.4013 20.1011 13.3094C20.1011 12.6656 20.1191 12.0713 20.1191 11.4523H21.9762V20.738C21.9762 21.9637 21.3448 22.5951 20.1191 22.5951H4.6429C3.41719 22.5951 2.78576 21.9637 2.78576 20.738V11.4523H5.26195C5.26195 12.0713 5.28052 12.6656 5.28052 13.3094ZM0.30957 4.13513V22.4837C0.30957 24.5693 1.43005 25.6904 3.51624 25.6904H21.8648C23.9504 25.6904 25.0715 24.5693 25.0715 22.4837V4.13513C25.0715 2.04956 23.9504 0.928467 21.8648 0.928467H3.51624C1.43005 0.928467 0.30957 2.04956 0.30957 4.13513ZM7.77529 13.3094C7.77529 10.5918 9.9729 8.3948 12.6905 8.3948C13.3361 8.39423 13.9754 8.52096 14.5719 8.76774C15.1685 9.01452 15.7105 9.37651 16.167 9.83299C16.6234 10.2895 16.9854 10.8315 17.2322 11.428C17.479 12.0245 17.6057 12.6639 17.6051 13.3094C17.6058 13.955 17.4791 14.5944 17.2324 15.1909C16.9856 15.7875 16.6236 16.3295 16.1671 16.786C15.7106 17.2425 15.1686 17.6045 14.572 17.8513C13.9755 18.098 13.3361 18.2247 12.6905 18.224C12.0449 18.2247 11.4055 18.098 10.8089 17.8513C10.2123 17.6045 9.67023 17.2426 9.21369 16.7861C8.75715 16.3296 8.39511 15.7875 8.14829 15.191C7.90147 14.5944 7.77471 13.955 7.77529 13.3094ZM18.262 4.95228C18.262 4.43847 18.6767 4.02371 19.1905 4.02371H21.0477C21.5615 4.02371 21.9762 4.43847 21.9762 4.95228V6.80942C21.9762 7.32323 21.5615 7.73799 21.0477 7.73799H19.1905C18.6767 7.73799 18.262 7.32323 18.262 6.80942V4.95228Z" fill={fillColor} />
    </svg>
  );
}