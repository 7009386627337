import React, { useEffect, useState } from 'react';

const useWindowWidth = () => {

  const isClient = typeof window === 'object';
  
  const getSize = () => {
    return (
      {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      }
    );
  }

  const [width, setWidth] = useState(getSize);


  useEffect(
    () => {
      if (!isClient) {
        return false;
      }

      const handleResize = () => setWidth(getSize());
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [width]
  );

  return width;
};

export default useWindowWidth;