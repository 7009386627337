import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import MainNav from '../components/main-site-nav/MainNav';
import MainFooter from '../components/MainFooter';

import '../components/assets/css/layout.scss';

export default ({ children }) => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <div className="layout">
      <MainNav />
      {children}
      <MainFooter />
    </div>
  );
};
